
body {
  background: pink;
  overflow: hidden;
}
.App {
  height: 90vh;
}
.App .title{
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  color: azure;
  -webkit-text-stroke: 1px purple;
}
.App .body{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 3fr 1fr;
  justify-items: center;
  align-items: center;
  column-gap: 5rem;
  height: 50%;
}
@media only screen and (max-width: 950px) {
  .App .body{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr 3fr 1fr;
    justify-items: center;
    align-items: center;
    row-gap: 5rem;
    column-gap: none;
    height: 50%;
  }
}
.App .body .input-container {
  display: grid;
  grid-template-rows: 1fr 1fr auto;
  width: 100%;
}
.App .body input {
  width: 100%;
  border-radius: 0.4rem;
  border: 1px solid purple;
  padding: 8px;
  box-sizing: border-box;
}
.App .body input:focus-visible {
  outline: 2px solid darkmagenta;
}
.App .body .search-button {
  border: 1px solid purple;
  border-radius: 0.4rem;
  background-color: white;
  justify-self: center;
  width: 10rem;
  font-weight: 600;
  font-size: 1.2rem;
  color: purple;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}
.App .body .search-button:hover {
  border: 2px solid #590059;
  color: #590059;
}
.App .body .search-button:active {
  border: 2px solid #330033 ;
  color: #330033 ;
}
.error {
  color: darkred;
  font-size: 12px;
}
.rotate {
  animation: rotation 5s infinite linear;
}
.rotate-2 {
  animation: rotation2 8s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes rotation2 {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.overlay {
  z-index: 1000;
  position: absolute;
  background-color: rgba(0,0,0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: 600;
  color:azure;
  -webkit-text-stroke: 1px pink;
  letter-spacing: 5px;
  text-align: center; 
  padding: 10px;
}
.overlay a {
  color: blue;
  -webkit-text-stroke: none;
}
.overlay a:hover {
  color: darkblue;
}
.overlay a:focus {
  color: black;
}